import React, { useEffect, useRef, useState, useContext } from "react";
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import GoogleMapReact from "google-map-react";

const MapBoxComponent = () => {
  const [map, setMap] = useState(null);
  const mapContainer = useRef(null);
  const [companyDetails] = useContext(CompanyContext);
  const [mapDetails, setMapDetails] = useState();
  var company_id_val = companyDetails && companyDetails.company_id;
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  useEffect(() => {
    (async function () {
      try {
        if (company_id_val) {
          var company_id = companyDetails.company_id
          var template_id = companyDetails.template_id
          const mapData = await fetch(
            config.apiEndpoint + '/wb-frontview/get-frontview-map-data/' + company_id + '/' + template_id
          );
          const result = await mapData.json();
          setMapDetails({ "hotel_details": result && result.hotel_details });

        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [company_id_val])

  // google map marker component
  const Marker = () => {
    return <div className={`google__map__marker__details`}></div>;
  };


  useEffect(() => {

    if (mapDetails && mapDetails.hotel_details) {
      let lat = parseFloat(mapDetails.hotel_details.latitude);
      let lng = parseFloat(mapDetails.hotel_details.longitude);
      setLatitude(lat);
      setLongitude(lng);
    }

  }, [map, mapDetails]);

  return (
    <div className="container-fluid google-map-wrap">
      <div className="map__component__wrapper" style={{ height: "445px" }}>
        {latitude || longitude ? (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyDhShnWZr1GVyY4fI8Xu6d6l4V954Mlktg",
            }}
            options={{
              mapTypeId: "roadmap",
              fullscreenControl: false,
            }}
            center={{
              lat: parseFloat(latitude),
              lng: parseFloat(longitude),
            }}
            zoom={16}
          >
            <Marker lat={latitude} lng={longitude} />
          </GoogleMapReact>
        ) : null}
      </div>
    </div>

  )


};

export default MapBoxComponent;